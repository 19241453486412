import { graphql } from "gatsby";
import * as React from "react";
import { unwrapFirst } from "../util";
import {
  Layout,
  HelmetSeo,
  HeroCoverBackground,
  HorizontalContainer,
  LinkTile,
  Section,
  SectionSocials,
  FormattedRichText,
} from "../components/legacy";
import { Color } from "../constants/legacy";
import WHITEPAPERS_HERO from "../../img/pages/whitepapers/whitepapers_hero.png";
import HERO_BACKGROUND_1 from "../../img/ui/patterns/hero_background_1.svg";
import styled from "styled-components";
import { parseISO, formatDistanceStrict } from "date-fns";
import { fluidImage } from "../prismic-tools";

type Props = {
  data: GatsbyTypes.WhitepapersPageQuery;
};

export const query = graphql`
  query WhitepapersPage {
    prismicWhitepapersPage {
      data {
        hero_title
        hero_sub_title {
          raw
        }

        cta_text

        items {
          item_title
          item_description
          item_topic
          item_publish_date
          item_read_minutes
          item_link
          item_image {
            alt
            dimensions {
              width
              height
            }
            url
          }
        }

        seo_title
        seo_description
        share_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
      }
    }
  }
`;

export default function WhitepapersPage({ data }: Props) {
  const page = data.prismicWhitepapersPage?.data;
  if (!page) throw new Error("page missing");

  return (
    <Layout>
      <HelmetSeo {...page} />

      {/* Unlike all other Hero components, this has a 2-column layout
                with image on the right side, which is a big enough difference
                that it's worth just doing it from scratch here */}
      <StyledHeroContainer>
        <StyledHeroLayer>
          <HeroCoverBackground src={HERO_BACKGROUND_1} />
        </StyledHeroLayer>

        <StyledHeroAreaMain>
          <StyledHeroColText>
            <h1>{page.hero_title}</h1>
            <FormattedRichText
              render={page.hero_sub_title?.raw}
              fontSize={16}
            />
          </StyledHeroColText>
          <StyledHeroColImage>
            <img src={WHITEPAPERS_HERO} />
          </StyledHeroColImage>
        </StyledHeroAreaMain>
      </StyledHeroContainer>

      <Section>
        <HorizontalContainer>
          <StyledTiles>
            {page.items?.map((item) => {
              if (!item) return;
              return (
                <LinkTile
                  href={item.item_link}
                  key={`${item.item_title}${item.item_link}`}
                  dark
                  imageFluid={
                    item.item_image && fluidImage(item.item_image, 400)
                  }
                  infoLeft={item.item_topic}
                  infoRight={
                    item.item_publish_date &&
                    formatDistanceStrict(
                      parseISO(item.item_publish_date),
                      new Date(),
                      { addSuffix: true }
                    )
                  }
                  title={item.item_title}
                  description={item.item_description}
                  readTime={item.item_read_minutes}
                />
              );
            })}
          </StyledTiles>
        </HorizontalContainer>
      </Section>
    </Layout>
  );
}

const StyledHeroContainer = styled.section`
  position: relative;
  background-color: ${Color.darkBlue};
`;

const StyledHeroLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  pointer-events: none;
  overflow: hidden;
`;

const StyledHeroAreaMain = styled(HorizontalContainer)`
  position: relative;
  min-height: 600px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${Color.white};

  @media (max-width: 640px) {
    min-height: 540px;
  }
`;

const StyledHeroColText = styled.div`
  flex: 3;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h1 {
    font-weight: 700;
    font-size: 42px;
    max-width: 490px;
    margin-bottom: 30px;
  }

  p {
    max-width: 490px;
  }
`;

const StyledHeroColImage = styled.div`
  flex: 2;
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 800px) {
    display: none;
  }
`;

const StyledTiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px -10px;
`;
